<template>
  <div>
    <CModal color="primary" :show="shows" size="lg">
      <template v-slot:header>
        <h5 class="modal-title font-weight-bold">Purchase History</h5>
        <button
          type="button"
          aria-label="Close"
          class="close text-white"
          @click="closeModal()"
        >
          ×
        </button>
      </template>
      <div v-if="data">
        <h4 class="text-center mt-3">{{ data.name }}</h4>
        <p class="text-center mt-3 text-muted">{{ data.product_code }}</p>
        <div class="table-responsive">
          <table class="table table-hover table-sm">
            <thead>
              <tr>
                <th scope="col" class="text-center">Purchase Price</th>
                <th scope="col" class="text-center">Stocks</th>
                <th scope="col" class="text-center">Purchased By</th>
                <th scope="col" class="text-center">Purchased Date</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="history in data.purchase_history" :key="history.id">
                <td class="text-center">
                  {{ history.purchase_price }}
                </td>
                <td class="text-center">
                  {{ history.stocks }}
                </td>
                <td class="text-center">
                  {{ history.purchased_by }}
                </td>
                <td class="text-center">
                  {{ history.created_at | moment("dddd, MMMM Do YYYY") }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <template v-slot:footer>
        <CButton color="primary" @click="closeModal">Close</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
export default {
  name: "StockPurchaseHistoryModal",
  props: ["data", "show"],
  computed: {
    shows() {
      return this.show;
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>
